
.control-panel {
    position: absolute;
    bottom: 50px;
    left: 0;
    max-width: 140px;
    box-shadow: 0 0 0 2px rgba(0 0 0 / 10%);
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 8px 16px;
    margin: 15px;
    font-size: 1px;
    line-height: 1;
    color: #6b6b76;
    text-transform: uppercase;
    outline: none;
  }